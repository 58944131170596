import { useState, useEffect, useContext } from 'react'
import fetchJsonp from 'fetch-jsonp'
import { Context } from './Store'

const UseGameUpdate = (id, pc, cid) => {

  const [state, dispatch] = useContext(Context);
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  var actionParam
  
  useEffect(() => {

    //reset for retry
    setIsError(false)

    if (action !== null) {

      //actionArr is used on Trail.js where it can't be initialized with one cid
      //Trail.js actionArr[0: action, 1: type, 2: id]
      const actionArr = action.split(',')
      
      var fieldToUpdate

      switch(actionArr[0]) {
        case "LOAD":
            actionParam = "load"
            break
        case "UNLOCK":
            var poiId
            if (actionArr[1] === "CACHE") {
              poiId = actionArr[2]
              cid = poiId
            } else if (actionArr[1] === "PHOTO") {
              poiId = actionArr[2] + "p"
            }
            
            fieldToUpdate = poiId + "-unlock"
            actionParam = "unlock"
            break
        case "FOUND":
            fieldToUpdate = cid + "-found"
            actionParam = "found"
            break
        case "MISSING":
            fieldToUpdate = cid + "-missing"
            actionParam = "missing"
            break
        case "REDEEM":
            actionParam = "redeem"
            break
        case "REDEEM_AGAIN":
            actionParam = "redeem"
            break
        default:
           break    
      }

      //Redeem only gets written from server fetch response
      if ((actionArr[0] !== "LOAD") && (actionArr[0] !== "REDEEM") && (actionArr[0] !== "REDEEM_AGAIN")) {
        //Updating local store
        dispatch({type: 'UPDATE_GAME', fieldToUpdate: fieldToUpdate });
      }

      const fetchData = async () => {
        setIsLoading(true);
        try {
          let url = process.env.REACT_APP_BACKEND_URL + '?r=game'
          
          var query
          if (actionArr[1] === "LOAD") {
            query = '&id=' + id + '&pc=' + pc + "&act=" + actionParam
          } else if (actionArr[1] === "PHOTO") {
            query = '&id=' + id + '&pc=' + pc + "&act=" + actionParam + "&pid=" + actionArr[2]
          } else {
            query = '&id=' + id + '&pc=' + pc + "&act=" + actionParam + "&cid=" + cid
          }

          const res = await fetchJsonp(url + query, {
                                      method: 'GET',
                                      jsonpCallbackFunction: 'callback',
                                    })
          const json = await res.json()
          //console.log(json)
          const gameObjServer = JSON.parse(json)
          
          //game object didn't exist -- load it
          if (!state.game.id) {
            console.log("setting game")
            dispatch({type: 'SET_GAME', payload: gameObjServer});
          }

          //Load redeem time from server into state. Other timestamps not critical
          if ((action === "REDEEM") || (action === "REDEEM_AGAIN"))  {
            dispatch({type: 'REDEEM', fieldToUpdate: cid + "-redeemed", payload: gameObjServer[cid + "-redeemed"]});
          }
          
        } catch (error) {
          console.log("fetching error: " + error )
          setIsError(true)
          //dispatch({type: 'SET_ERROR', payload: error});

          //offline dev mode:
          const json = '{"id":"2", "pc":"JUY", "mc":"0GR", "link":"/#/play?id=2&pc=JUY", "email":"", "paid":"", "players":"", "1p-unlock":"", "2p-unlock":"", "3p-unlock":"", "4p-unlock":"", "1-fcode":"CHOC", "1-unlock":"", "1-found":"", "1-redeemed":"", "2-fcode":"CHU", "2-unlock":"", "2-found":"", "2-redeemed":"", "3-fcode":"NUT", "3-unlock":"", "3-found":"", "3-redeemed":"", "4-fcode":"ICE", "4-unlock":"", "4-found":"", "4-redeemed":""}'
          const gameObjServer = JSON.parse(json)
          if (!state.game.id) {
            console.log("setting game")
            dispatch({type: 'SET_GAME', payload: gameObjServer});
          }

        }
        setIsLoading(false);
      };
      fetchData();
    } else {
      console.log("no game update")
    }
  }, [action]);
  return [{isLoading, isError}, setAction];
};

export default UseGameUpdate