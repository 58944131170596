import React, { useEffect, useContext, useState } from 'react'
import Process from './components/Process'
import SocialMedia from './components/SocialMedia'
//import CachePreview from './components/CachePreview'
import ModalQuestion from './components/ModalQuestion';
//import { GameContext } from './context'
import UseGameUpdate from './utils/UseGameUpdate'
import { Context } from './utils/Store'
import { HashLink as Link } from 'react-router-hash-link'
import detectBrowserLanguage from 'detect-browser-language'

const Play = (props) => {

  var locale
  if (detectBrowserLanguage().substring(0, 2) === 'es') {
    locale = 'es'
  } else {
    locale = 'en'
  }

  const languagesStringData = {
    en: require('./locale/strings-en.json'),
    es: require('./locale/strings-es.json')
  };
  
  const strings = languagesStringData[locale];

  const [state] = useContext(Context);

  //Game loading if needed

  const queryString = require('query-string');
  const param = queryString.parse(props.location.search);

  const [{isLoading}, gameUpdate] = UseGameUpdate(param['id'], param['pc'])

  useEffect(() => {
    if (!state.game.id) {
      gameUpdate("LOAD")
    }
  }, []);

  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  var iosNotSafari = iOS && !iOSSafari

  if (!state.error && state.game.id) {
  	return (
      <div className="center">

        <section className="section bg-home home-half" id="home">
              <div className="bg-overlay"></div>
              <div className="display-table">
                  <div className="display-table-cell">
                      <div className="container">
                          <div className="row vertical-content">
                              <div className="col-lg-7 text-white text-left margin-t-30">
                                  <h1 className="home-title">El Dulce Camino</h1>
                                  <p className="padding-t-15 home-desc home-subtitle-width-100">{strings.tagline}</p>
                                  <Link smooth to={"/play?id=" + param['id'] + "&pc=" + param['pc'] + "#start-hunt"} className="btn btn-custom margin-t-30 waves-effect waves-light">{strings['pre-hunt-checklist-btn']} <i className="mdi mdi-arrow-right"></i></Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

        <Process 
          strings={strings}
        />

        <div className="col-lg-8 offset-lg-2 margin-t-20" id="start-hunt">
                <h1 className="section-title text-center">{strings['pre-hunt-checklist']}</h1>
                <div className="section-title-border margin-t-20"></div>
                <div className="section-subtitle font-secondary text-muted text-center padding-t-30" >{strings['pre-hunt-checklist-subtitle']}</div>  
        </div>
        <div className="checklist-border">
          <ul>
            {iosNotSafari ? 
            <li>
              {strings['offline-warning']}
            </li>
            : null}

            {iOS ? 
            <li>
              {strings['install']} <a href="itms-apps://itunes.apple.com/app/apple-store/id585027354">Google Maps</a>.
            </li>
            : null}
            <li>
              {strings['checklist-1']}
            </li>
            <li>
              {strings['checklist-2']}
            </li>
            <li>
              {strings['checklist-3']}
            </li>
          </ul>

          <div className="spacer text-center"><Link className="btn btn-custom" to={"/trail" + props.location.search }>{strings['enough-already-btn']}</Link></div>
        </div>
        

         {/* SocialMedia Component*/}
          
        <SocialMedia
            strings={strings}
        />
      </div>
   )
  }  else {
        return (
         <div className="loading-img">
            <div className="flex flex-horizontal-center"><img className="horizontal-center-item" src="/images/loader.gif" /></div>
            <div className="flex flex-horizontal-center">{strings.loading}</div>
         </div>
        );
  }
}

export default Play
