import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDistance } from 'geolib';


const ModalRadar = (props) => {
  const geolib = require('geolib')
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [distance, setDistance] = useState(props.strings['locating'])

  if (navigator.geolocation) {
    
    navigator.geolocation.watchPosition(locationSuccess, locationError, {enableHighAccuracy: true})
  
  } else {
    console.log('location disabled')
    setLocationEnabled(false);
  }

  function locationSuccess(pos) {
    const distance = geolib.getDistance({
                  latitude: pos.coords['latitude'],
                  longitude: pos.coords['longitude']}, {
                  latitude: props.cache['lat'],
                  longitude: props.cache['lng'],
              })
    let distanceStr = "";

    if (distance < 3)
      distanceStr = props.strings['radar-close']
    else
      distanceStr = distance.toString() + ' meters'


    setDistance(distanceStr)
  }

  function locationError(err) {
    setLocationEnabled(false);
    console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.strings['radar']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {locationEnabled ?
            <React.Fragment>
            <div>{props.strings['distance-to-treasure']}</div>
            <div className="h3">{distance}</div>
            </React.Fragment>
          :
            <div>[{props.strings['no-location']}]</div>}   

          <div className="margin-t-50">{props.strings['radar-tip']}</div>

          <div>
            
          </div>

        </Container>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
}

export default ModalRadar