import React, { useEffect, useContext, useState } from 'react'
import SocialMedia from './components/SocialMedia';
import { Link } from 'react-router-dom';
import ModalQuestion from './components/ModalQuestion';
import ModalOfflineMap from './components/ModalOfflineMap';
import UseGameUpdate from './utils/UseGameUpdate'
import { Context } from './utils/Store'
import detectBrowserLanguage from 'detect-browser-language'

const Photo = (props) => {

  const queryString = require('query-string');
  const param = queryString.parse(props.location.search);
  
  //Multilanguage
  var locale
  if (detectBrowserLanguage().substring(0, 2) === 'es') {
    locale = 'es'
  } else {
    locale = 'en'
  }


  const languagesPhotoData = {
    en: require('./locale/photoData-en.json'),
    es: require('./locale/photoData-es.json')
  };
  
  const photo = languagesPhotoData[locale][param['pid']-1]

  const languagesStringData = {
    en: require('./locale/strings-en.json'),
    es: require('./locale/strings-es.json')
  };
  
  const strings = languagesStringData[locale];
  
  const [state] = useContext(Context);

  const [{isLoading}, gameUpdate] = UseGameUpdate(param['id'], param['pc'])


  useEffect(() => {
    const gameUpdater = async () => {
      console.log("photo")
      gameUpdate("LOAD")
      
    }
    gameUpdater()
   
  }, []);

  const handleMapNav = () => {

    if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) || 
     (navigator.platform.indexOf("iPad") != -1) || 
     (navigator.platform.indexOf("iPod") != -1))
        window.location = "comgooglemaps://?daddr=" + photo.lat + "," + photo.lng + "&directionsmode=walking"
    else /* else use Google */
        window.location = 'https://maps.google.com/?q=' + photo.lat + "," + photo.lng

  }

  const [modalOfflineMapShow, setModalOfflineMapShow] = useState(false)
  const [modalOfflineMapImgPath, setModalOfflineMapImgPath] = useState()

  const modalOfflineMapShower = () => {

    setModalOfflineMapImgPath(photo.offlineMap)
    setModalOfflineMapShow(true)
  }

    return (
      <div>

        <div className="col-lg-8 offset-lg-2 margin-t-20" id="start-hunt">
              <h1 className="section-title text-center">{strings['photo-stop']}</h1>
              <div className="section-title-border margin-t-20"></div> 
              
              <div className="insta-border margin-t-20">
                <div className="insta-top-parent">
                  <div className="insta-avatar"><img src="/images/instagram/user.svg"/></div>
                  <div className="insta-handle">
                    <div><b>{strings['your-insta-handle']}</b></div>
                    <div>{strings['insta-tip']}</div>
                  </div>
                </div>
                <img className="insta-img" src={"/images/instagram/" + photo.imgInsta} />
                <div>
                  <img className="insta-icons" src="/images/instagram/heart.svg"/>
                  <img className="insta-icons" src="/images/instagram/comment.svg"/>
                  <img className="insta-icons" src="/images/instagram/dm.svg"/>
                </div>
              </div>
        </div>

        <div className="flex flex-horizontal-center margin-t-20">

        {navigator.onLine ?
            <button onClick={() => handleMapNav()} className="btn btn-custom btn-space">{strings['google-maps-btn']}</button>
          :
            <button onClick={() => modalOfflineMapShower()} className="btn btn-custom btn-space">{strings['offline-maps-btn']}</button>
        }
        </div>

        <div className="section-title-border margin-t-30"></div> 

        <div className="flex flex-horizontal-center margin-t-20"><h3>{strings['unlock-next']}</h3></div>

        <div className="section-subtitle font-secondary text-muted text-center" >{strings['question-tip']}</div> 

        <div className="margin-t-20">
          <ModalQuestion
            photo={photo}
            queryString={props.location.search}
            pid={param['pid']}
            gameData={state.game}
            strings={strings}
          />
        </div>

        <ModalOfflineMap
          show={modalOfflineMapShow}
          onHide={() => setModalOfflineMapShow(false)}
          closeModal={() => setModalOfflineMapShow(false)}
          imgPath={modalOfflineMapImgPath}
          strings={strings}
          stopData={photo}
          type={"PHOTO"}
        />
        
        {/* SocialMedia Component*/}
        <div className="spacer">
          <SocialMedia 
          strings={strings}
          />

        </div>

  
      </div>
    )
}

export default Photo