import React from 'react';

const SocialMedia = (props) => {
    return (
        <section className="cta bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 social-center">
                        <ul className="list-inline social margin-t-20">
                            <li className="list-inline-item"> <a href={props.strings['instagram-link']} className="social-icon"><i className="mdi mdi-instagram"></i></a></li>
                            <li className="list-inline-item"> <a href={props.strings['whatsapp-link']} className="social-icon"><i className="mdi mdi-whatsapp"></i></a></li>
                            <li className="list-inline-item"> <a href={"mailto:" + props.strings['support-email']} className="social-icon"><i className="mdi mdi-email"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default SocialMedia;