import React, { useEffect, useContext, useState } from 'react'
import SocialMedia from './components/SocialMedia';
import { Link } from 'react-router-dom';
import ModalHint from './components/ModalHint';
import ModalRadar from './components/ModalRadar';
import ModalImg from './components/ModalImg';
import ModalOfflineMap from './components/ModalOfflineMap';
import ModalValidate from './components/ModalValidate';
import ModalRedeem from './components/ModalRedeem';
import UseGameUpdate from './utils/UseGameUpdate'
import { Context } from './utils/Store'
import detectBrowserLanguage from 'detect-browser-language'

const Cache = (props) => {

  const queryString = require('query-string');
  const param = queryString.parse(props.location.search);
  
  //Multilanguage
  var locale
  if (detectBrowserLanguage().substring(0, 2) === 'es') {
    locale = 'es'
  } else {
    locale = 'en'
  }

  const languagesStringData = {
    en: require('./locale/strings-en.json'),
    es: require('./locale/strings-es.json')
  };
  
  const strings = languagesStringData[locale];

  const languagesCacheData = {
    en: require('./locale/cacheData-en.json'),
    es: require('./locale/cacheData-es.json')
  };
  
  const cacheData = languagesCacheData[locale];

  const cache = cacheData[param['cid']-1]
  const [state] = useContext(Context);

  const [{isLoading, isError}, gameUpdate] = UseGameUpdate(param['id'], param['pc'], param['cid'])

  const [disableRedeemBtn, setDisableRedeemBtn] = useState(true)

  //modal states
  const [modalImgShow, setModalImgShow] = useState(false)
  const [modalImgPath, setModalImgPath] = useState()
  const [modalHintShow, setModalHintShow] = useState(false)
  const [modalRadarShow, setModalRadarShow] = useState(false)
  const [modalValidateState, setModalValidateState] = useState({show: false,
                                                          step: 1,
                                                          btnTitle: strings['validator-btn']})
  const [modalRedeemState, setModalRedeemState] = useState({show: false,
                                                          step: 1,
                                                          btnTitle: strings['redeem-btn']})

  useEffect(() => {
    
    const gameUpdater = async () => {
      var action
      if (param['act'] == "unlock") {
        console.log("cache unlock")
        action = "UNLOCK"
      } else {
        console.log("cache load")
        action = "LOAD"
      }
      gameUpdate(action)
      
    }
    gameUpdater()
   
  }, []);

  //Update validator button to match step
  useEffect(() => {
   handleValidator()
   handleRedeem()
  }, [state.game])

  const [anchorTarget, setAnchorTarget] = useState(null)

  useEffect(() => {
    setAnchorTarget(document.getElementById("step3"))
  }, [])

  const updateValidateStep = (step) => {
    setModalValidateState(prevState => ({...prevState, step: step}))
    switch(step) {
      case 2:
        gameUpdate("FOUND")
        //setEnableRedeemBtn(true)
        break
      case 3:
        handleValidator(false)
        break
    }
  }

  function isEmpty(obj) {

    if ((obj === "") || (obj == null)) {
      return true
   }
    return false
  }

  const handleValidator = (show) => {
    //determine first step to show
    if (!isEmpty(state.game[cache.cid + "-found"])) {
      setModalValidateState(prevState => ({...prevState, step: 2, btnTitle: strings['validated-btn']}))
      setDisableRedeemBtn(false)
    }
    
    if (show) { 
      setModalValidateState(prevState => ({...prevState, show: true}))
    } else if (show === false) {
      setModalValidateState(prevState => ({...prevState, show: false}))
      console.log("should scroll")
      //anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      var elementPosition = anchorTarget.getBoundingClientRect().top;
        window.scrollTo({
           top: elementPosition + window.pageYOffset,
           behavior: "smooth"
      })
    }
  }

  //logic for redeeming

  const [redeemRetryToggle, setRedeemRetryToggle] = useState("REDEEM")

  const redeemUpdate = () => {
    gameUpdate(redeemRetryToggle)
    if (redeemRetryToggle === "REDEEM")
    {
      setRedeemRetryToggle("REDEEM_AGAIN")
    } else {
      setRedeemRetryToggle("REDEEM")
    }
  }

  const handleRedeem = (show) => {

    var isRedeemed = false
    //determine first step to show
    if (!isEmpty(state.game[cache.cid + "-redeemed"])) {
      setModalRedeemState(prevState => ({...prevState, step: 1, btnTitle: strings['redeemed-btn']}))
      isRedeemed = true
    } 
    
    if (show) {

      if (!isRedeemed) {
        redeemUpdate()
      }
      
      setModalRedeemState(prevState => ({...prevState, show: true}))
    } else if (show === false) {
      setModalRedeemState(prevState => ({...prevState, show: false}))
    }
  }

  const modalImgShower = (type) => {
    if (type === "HINT") {
      setModalImgPath("/images/cache/" + cache.hintImg )
      setModalHintShow(false)
    }
    setModalImgShow(true)
  }

  const [modalOfflineMapShow, setModalOfflineMapShow] = useState(false)
  const [modalOfflineMapImgPath, setModalOfflineMapImgPath] = useState()

  const modalOfflineMapShower = () => {

    setModalOfflineMapImgPath(cache.offlineMap)
    setModalOfflineMapShow(true)
  }

  const handleMapNav = () => {

    if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) || 
     (navigator.platform.indexOf("iPad") != -1) || 
     (navigator.platform.indexOf("iPod") != -1))
        window.location = "comgooglemaps://?daddr=" + cache.lat + "," + cache.lng + "&directionsmode=walking"
    else /* else use Google */
        window.location = cache.mapLink
  }

  //missing cache logic
  const notifyMissing = async () => {

    gameUpdate("FOUND")

    try {
      let url = 'https://maker.ifttt.com/trigger/missing_cache/with/key/fU_PfI1dpzOaWQINY7BNJjdt5msqOVNLa7gaQyog8nN?value1=' + cache.cid + "&value2=" +param['id'] 
      const res = fetch(url, {method: "POST"})
      
    } catch (error) {
      console.log(error)
    }
  }

  // if (!state.error && state.game.id) {
    return (
      <div className="max-width-page">
        
        <div className="col-lg-8 offset-lg-2 margin-t-20" id="start-hunt">
              <h1 className="section-title text-center">{strings['sweet-stop']}</h1>
              <div className="section-title-border margin-t-20"></div> 
              <div className="section-subtitle font-secondary text-muted text-center margin-t-20" >{strings['treat-img-intro']}</div>
              <div ><img className="cache-img margin-t-20" src={"/images/cache/" + cache.imgTreat} /></div>
        </div>

        <div className="flex flex-horizontal-center margin-t-20 sticky-step"><h3>{strings['step1']}</h3></div>

        <div className="section-subtitle font-secondary text-muted text-center margin-t-20" >{strings['nav-tip']}</div>
        
        <div className="flex flex-horizontal-center margin-t-20"><img className="img-400" src="images/howto/1.svg" /></div>

        <div className="flex flex-horizontal-center margin-t-20">
          {navigator.onLine ?
            <button onClick={() => handleMapNav()} className="btn btn-custom btn-space">{strings['google-maps-btn']}</button>
          :
            <button onClick={() => modalOfflineMapShower()} className="btn btn-custom btn-space">{strings['offline-maps-btn']}</button>
          }
          
          <button onClick={() => setModalRadarShow(true)} className="btn btn-custom btn-space">Radar</button>

          <button onClick={() => setModalHintShow(true)} className="btn btn-custom btn-space">{strings['hint-btn']}</button>
        </div>  
        
        <div className="flex flex-horizontal-center margin-t-20 sticky-step"><h3>{strings['step2']}</h3></div>

        <div className="section-subtitle font-secondary text-muted text-center margin-t-20" >{strings['open-tip']}</div>

        <div className="flex flex-horizontal-center margin-t-20"><img className="img-400" src="images/howto/2.svg" /></div> 

        <div className="flex flex-horizontal-center margin-t-20"><button onClick={() => handleValidator(true)} className="btn btn-custom">{modalValidateState.btnTitle}</button></div>

        <div className="text-centered margin-t-20 sticky-step" id="step3"><h3>{strings['step3']}</h3></div>

        <div className="section-subtitle font-secondary text-muted text-center margin-t-20" >{strings['go-inside-tip']}</div>

        <div ><img className="cache-img margin-t-20" src={"/images/cache/" + cache.imgStore} /></div>

        <div className="section-subtitle font-secondary text-muted text-center margin-t-20" >{strings['redeem-tip']}</div>

        <div className="checklist-border">
          <ul>
            <li>
              {strings['redeem-tip1']}
            </li>
            <li>
              {strings['redeem-tip2']}
            </li>
          </ul>

          <div className="flex flex-horizontal-center margin-t-20"><button onClick={() => handleRedeem(true)} className="btn btn-custom" disabled={disableRedeemBtn}>{modalRedeemState.btnTitle}</button></div>
        
        </div>

        <div className="col-lg-8 offset-lg-2 margin-t-50">
              <h1 className="section-title text-center">{strings['while-you-eat']}</h1>
              <div className="section-title-border margin-t-20"></div>
        </div>

        <div className="section-subtitle font-secondary text-muted text-center margin-t-20">{ cache.desc }</div>
      
        <ModalHint
          show={modalHintShow}
          onHide={() => setModalHintShow(false)}
          closeModal={() => setModalHintShow(false)}
          cacheId={cache.id}
          hint={cache.hint}
          hintImg={cache.hintImg}
          modalImgShower={modalImgShower}
          strings={strings}
        />

        <ModalRadar
          show={modalRadarShow}
          onHide={() => setModalRadarShow(false)}
          closeModal={() => setModalRadarShow(false)}
          cacheId={cache.id}
          strings={strings}
          cache={cache}
        />

        <ModalImg
          show={modalImgShow}
          onHide={() => setModalImgShow(false)}
          closeModal={() => setModalImgShow(false)}
          imgPath={modalImgPath}
          notifyMissing={notifyMissing}
          strings={strings}
        />

        <ModalOfflineMap
          show={modalOfflineMapShow}
          onHide={() => setModalOfflineMapShow(false)}
          closeModal={() => setModalOfflineMapShow(false)}
          imgPath={modalOfflineMapImgPath}
          strings={strings}
          stopData={cache}
          type={"CACHE"}
        />

        <ModalValidate
          show={modalValidateState.show}
          step={modalValidateState.step}
          updateValidateStep={updateValidateStep}
          onHide={() => setModalValidateState(prevState => ({...prevState, show: false}))}
          closeModal={() => setModalValidateState(prevState => ({...prevState, show: false}))}
          cache={cache}
          gameData={state.game}
          strings={strings}
        />

        <ModalRedeem
          show={modalRedeemState.show}
          step={1}
          onHide={() => setModalRedeemState(prevState => ({...prevState, show: false}))}
          closeModal={() => setModalRedeemState(prevState => ({...prevState, show: false}))}
          cache={cache}
          gameData={state.game}
          redeemUpdate={redeemUpdate}
          isError={isError}
          isLoading={isLoading}
          strings={strings}
        />

        <div className="spacer"></div>

        <div className="flex flex-horizontal-center"><Link to={"/trail?id=" + param['id'] + "&pc=" +  param['pc']} className="btn btn-custom">{strings['back-treat-map']}</Link></div>
        
        {/* SocialMedia Component*/}
        <div className="spacer">
          <SocialMedia
              strings={strings}
          />
        </div>
      </div>
    )
}

export default Cache;