import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ModalImg = (props) => {

  const [missingBtnText, setMissingBtnText] = useState(props.strings['report-missing'])
  const [disableBtn, setDisableBtn] = useState(false)
  const [isMissing, setIsMissing] = useState(false)

  const handleMissing = () => {
    setDisableBtn(true)
    setMissingBtnText("Reported")
    setIsMissing(true)
    props.notifyMissing()
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.strings['hint-btn']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div><img className="hint-pic img-400" src={props.imgPath} />
          <div className="flex flex-horizontal-center margin-t-20">
            {isMissing ?
            <div>{props.strings['missing-sorry']}</div>
            :
            <button className="btn btn-custom" onClick={()=> handleMissing()}>{missingBtnText}</button>}
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
}

export default ModalImg