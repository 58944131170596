import React from 'react'
import fetchJsonp from 'fetch-jsonp'

const Clear = async (props) => {

  console.log("clear")

  const queryString = require('query-string');
  const param = queryString.parse(props.location.search);
  
  try {
    let url = 'https://script.google.com/macros/s/AKfycbweCXatpM2Xy3H47sx6LVPHzc1n4VIvvHt-oVCKRcvbLQvJQmHu/exec?r=game&id=' + param['id'] + '&pc=' + param['pc'] + '&clear=true'
    const res = await fetchJsonp(url, {
      method: 'GET',
      jsonpCallbackFunction: 'callback',
    })
    const json = await res.json()
  
  } catch (error) {

  }

  return (<div>json</div>)
}

export default Clear