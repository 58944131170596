import React, { useState, useEffect } from 'react'

const FCodeChecker = (props) => {

  const [inputFCode, setInputFCode] = useState("")
  const [isInvalidCode, setIsInvalidCode] = useState(false)

  const handleChange = (e) => {
    setInputFCode(e.target.value)
    setIsInvalidCode(false)
  }

  const validateFCode = () => {
    var actualFCode = props.gameData[props.cid + "-fcode"].toUpperCase();

    if (inputFCode.toUpperCase().trim() === actualFCode) {
      props.updateValidateStep(2);

    } else {
      //changing style for wrong answer
      setIsInvalidCode(true)
    }
    
  }


  return (
          <div>
              <div className="modal-text">{props.strings['enter-code']}</div>
              <div><input className={isInvalidCode ? "margin20 input-wrong" : "margin20"} type="text" onChange={handleChange} /></div>
              <div><button className="btn btn-custom" onClick={() => validateFCode()} >{props.strings['validator-btn']}</button></div>
          </div>
  )
}

export default FCodeChecker;


  // async postAction(action, cacheId) {

  //   //Check to see if already unlocked
  //   const localGameData = localStorage.getItem("g-"+this.props.gameData.id+"pc-"+this.props.gameData.pcode.toLowerCase());

  //   if (localGameData) {
  //     var gameObj = JSON.parse(localGameData);

  //     switch(action) {
  //       case "unlock":
  //          gameObj[cacheId + "-unlock"] = new Date();
  //          break
  //       case "found":
  //          gameObj[cacheId + "-found"] = new Date();
  //          break
  //       case "missing":
  //          gameObj[cacheId + "-missing"] = "MISSING: " + new Date();
  //          break
  //       case "redeem":
  //          gameObj[cacheId + "-redeem"] = new Date();
  //          break          
  //     }
      
  //     localStorage.setItem("g-"+this.props.gameData.id+"pc-"+this.props.gameData.pcode.toLowerCase(), JSON.stringify(gameObj));

  //     this.setState({gameData: gameObj, 
  //                        validGame: true});
      
  //     //Try to update backend if available
  //     try {
  //       const response = await fetch('https://script.google.com/macros/s/AKfycbweCXatpM2Xy3H47sx6LVPHzc1n4VIvvHt-oVCKRcvbLQvJQmHu/exec?r=game&id=' + this.props.gameData.id + '&pc=' + this.props.gameData.pcode + "&cid=" + cacheId + "&act=" + action, { method: 'post' });
  //       const json = await response.json();

  //       // //Check to see if the mcodes need to be updated
  //       // var mcodeUpdate = new Date(gameObj["mcode-update"]);
  //       // var today = new Date();

  //       // if (mcodeUpdate.getDate() !== today.getDate())
  //       // {
  //       //   const remoteGameObj = JSON.parse(json);
          
  //       //   //Set data sources with new mcodes
  //       //   gameObj["1-mcode"] =  remoteGameObj["1-mcode"];
  //       //   gameObj["2-mcode"] =  remoteGameObj["2-mcode"];
  //       //   gameObj["3-mcode"] =  remoteGameObj["3-mcode"];
  //       //   gameObj["4-mcode"] =  remoteGameObj["4-mcode"];
  //       //   gameObj["mcode-update"] =  remoteGameObj["mcode-update"];

  //       //   localStorage.setItem("g-"+this.props.gameData.id+"pc-"+this.props.gameData.pcode.toLowerCase(), JSON.stringify(gameObj));
  //       //   this.setState({gameData: gameObj, 
  //       //                  validGame: true});

  //       // }

  //     } catch (err) {
  //       console.log("offline");
  //     }

  //   } else {

  //     //No local game data yet
  //     try {
  //       const response = await fetch('https://script.google.com/macros/s/AKfycbweCXatpM2Xy3H47sx6LVPHzc1n4VIvvHt-oVCKRcvbLQvJQmHu/exec?r=game&id=' + this.props.gameData.id + '&pc=' + this.props.gameData.pcode + "&cid=" + cacheId + "&act=" + action, { method: 'post' });
  //       const json = await response.json();
  //       localStorage.setItem("g-"+this.props.gameData.id+"pc-"+this.props.gameData.pcode.toLowerCase(), json);

  //       if (json === "invalid code") {
  //         this.setState({validGame: false});
  //       } else {
  //         this.setState({gameData: gameObj, 
  //                        validGame: true});
  //       }

  //     } catch (err) {
  //       console.log("offline");
  //     }

  //   }

  //   return true;
  // }