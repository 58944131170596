import React, { useState, useEffect } from 'react'
import { Modal, Container} from 'react-bootstrap';
import FCodeChecker from './FCodeChecker';
import ModalReturnCache from './ModalReturnCache';
import UseGameUpdate from '../utils/UseGameUpdate'

const ModalValidate = (props) => {

  //const [{isLoading, isError}, gameUpdate] = UseGameUpdate(props.gameData.id, props.gameData.pc, props.cache.cid)
  //const [errorFlag, setErrorFlag] = useState(false)


  const modalSwitch = (step) => {
    switch(step) {
      case 1:
        return(
          <React.Fragment>
            <Modal.Header closeButton> 
             <Modal.Title id="contained-modal-title-vcenter">
                {props.strings['validator-btn']}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <FCodeChecker
                  cid = {props.cache.cid}
                  gameData = {props.gameData}
                  updateValidateStep = {props.updateValidateStep}
                  strings={props.strings}
                />
              </Container>
            </Modal.Body>
          </React.Fragment>
        )
        break       

      case 2:
        return( 
          <React.Fragment>
            <Modal.Header closeButton> 
             <Modal.Title id="contained-modal-title-vcenter">
                {props.strings['treat-unlocked']}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <ModalReturnCache
                  strings = {props.strings}
                  updateValidateStep = {props.updateValidateStep}
                />
              </Container>
            </Modal.Body>
          </React.Fragment>
        )
        break  
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      {modalSwitch(props.step)}
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalValidate