import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as clipboard from "clipboard-polyfill/dist/clipboard-polyfill.promise"

const ModalOfflineMap = (props) => {

  const [copyCoordsBtn, setCopyCoordsBtn] = useState(props.strings['copy-coords'])

  const handleCopyCoords = () => {
    clipboard.writeText(props.stopData.lat + "," + props.stopData.lng);
    setCopyCoordsBtn(props.strings['copied'])

  }


  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.strings['offline-map-tip']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <Container>
          <div><img className="hint-pic" src={props.imgPath} /></div>
          
          <div>{props.strings['paste-coordinates-tip']}</div>
          <div>{props.stopData.lat + "," + props.stopData.lng}</div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div><button className="btn btn-custom margin-top-20" onClick={() => handleCopyCoords()}>{copyCoordsBtn}</button></div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalOfflineMap