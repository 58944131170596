const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_GAME':
            return {
                ...state,
                game: action.payload
            };
        case 'UPDATE_GAME':
            return {
                ...state,
                game: { ...state.game,
                    [action.fieldToUpdate]: new Date()
                }
            };
        case 'REDEEM':
            return {
                ...state,
                game: { ...state.game,
                    [action.fieldToUpdate]: action.payload
                }
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;