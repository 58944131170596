import React from 'react'
import { Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ModalHint = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.strings['hint-btn']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="modal-text">{props.hint}</div>
          <div>
            <button className="btn btn-custom margin20" onClick={()=>props.modalImgShower("HINT")}>{props.strings['give-up']}</button>
          </div>

        </Container>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
}

export default ModalHint