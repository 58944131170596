import React, { useState, useEffect } from 'react'
import { Modal, Container} from 'react-bootstrap';
import FCodeChecker from './FCodeChecker';
import { Link } from 'react-router-dom'
import UseGameUpdate from '../utils/UseGameUpdate'

const ModalRedeem = (props) => {

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const redeemTime = () => {
    const date = new Date (props.gameData[props.cache['cid'] + "-redeemed"])
    return(date.getDate() + "/" + (date.getMonth() + 1) + " " + formatAMPM(date))
  }
  
  const message = (isError, isLoading) => {
    if (isError) {
      return(
        <React.Fragment> 
        <div>
          <div>{props.strings['offline']}</div>
          <div>{"SSID: " + props.cache.ssid}</div>
           <div>{"PW: " + props.cache.pw}</div>
          <div><button className="btn btn-custom margin20" onClick={() => props.redeemUpdate()} >{props.strings['offline-try-again']}</button></div>
        </div>
        </React.Fragment> 
      )
    } else {
      if (isLoading) {
        return (
            <React.Fragment>
            <div>
              <div className="height-50vh"></div>
              <div className="spinner"></div>
            </div>
            </React.Fragment>
          )
        } else {
            return (
              <React.Fragment>
              <div>
                <div>{props.strings['good-for-one'] + " " + props.cache.treat}</div>
                <div className="merchant-code">{props.gameData.mc + " " + redeemTime()}</div>
                <div>{props.strings['demo-warning']}</div>
                <div><Link to={"/trail?id=" + props.gameData.id + "&pc=" +  props.gameData.pc} className="btn btn-custom margin-t-20">{(props.cache['cid'] === "4") ? props.strings['last-stop'] : props.strings['stop-unlocked-btn']}</Link></div>
              </div>
              </React.Fragment>
            )
        }
    }
  }

  const modalSwitch = (step) => {
    switch(step) {
      case 1:
          return(
            <React.Fragment> 
              <Modal.Header closeButton> 
               <Modal.Title id="contained-modal-title-vcenter">
                  {props.strings['show-cashier']}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  {message(props.isError, props.isLoading, props.isRedeeming)}
                </Container>
              </Modal.Body>
            </React.Fragment>
          )
        break       
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      {modalSwitch(props.step)}
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalRedeem