import React, { useState } from 'react'

const ModalReturnCache = (props) => {

  return (
    <div>

      <div className="flex flex-horizontal-center"><img className="horizontal-center-item" src="/images/loader.gif" /></div>

      <div>{props.strings["return-treasure"]}</div>

      <div><button className="btn btn-custom margin20" onClick={() => props.updateValidateStep(3)} >{props.strings['returned']}</button></div>
  
    </div>
  )
    
}

export default ModalReturnCache