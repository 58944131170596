import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
import * as registerServiceWorker from './registerServiceWorker';
import Play from './Play';
import Trail from './Trail';
import Cache from './Cache';
import Photo from './Photo';
import Clear from './Clear';
import ScrollIntoView from "./components/ScrollIntoView";
//import { GameProvider } from './context'
import Store from "./utils/Store";

import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="full-vert">
            <Switch>
              <Route path="/" exact component={Play} />
              <ScrollIntoView>
                <Route path="/play*"  component={Play} />
                <Route path="/trail*"  component={Trail} />
              	<Route path="/cache*" component={Cache} />
                <Route path="/photo*" component={Photo} />
                <Route path="/clear*" component={Clear} />
              </ScrollIntoView>
            </Switch>
      </div>
    </BrowserRouter>
  );
}

ReactDOM.render(<Store><App /></Store>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker.register();
