import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ModalMapTap = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Offline Mode
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div>Start with the chocolate map icon at the bottom and work up.</div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-custom" onClick={()=> props.closeModal()}>Got it</button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalMapTap