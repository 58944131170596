import React, { useEffect, useContext, useState } from 'react'
import Pricing from './components/Pricing'
import Process from './components/Process'
import Started from './components/Started'
import SocialMedia from './components/SocialMedia'
import ModalQuestion from './components/ModalQuestion';
import UseGameUpdate from './utils/UseGameUpdate'
import { Context } from './utils/Store'
import { HashLink as Link } from 'react-router-hash-link'
import detectBrowserLanguage from 'detect-browser-language'
import Map from './components/Map';
import ImageMapper from 'react-image-mapper';
import ModalMapTip from './components/ModalMapTip';


const Trail = (props) => {

  //Multilanguage
  var locale
  if (detectBrowserLanguage().substring(0, 2) === 'es') {
    locale = 'es'
  } else {
    locale = 'en'
  }

  const languagesCacheData = {
    en: require('./locale/cacheData-en.json'),
    es: require('./locale/cacheData-es.json')
  };
  
  const cacheData = languagesCacheData[locale];

  const languagesPhotoData = {
    en: require('./locale/photoData-en.json'),
    es: require('./locale/photoData-es.json')
  };
  
  const photoData = languagesPhotoData[locale];

  const languagesStringData = {
    en: require('./locale/strings-en.json'),
    es: require('./locale/strings-es.json')
  };
  
  const strings = languagesStringData[locale];

  const [state] = useContext(Context);

  //Game loading if needed

  const queryString = require('query-string');
  const param = queryString.parse(props.location.search);

  const [{isLoading}, gameUpdate] = UseGameUpdate(param['id'], param['pc'])

  useEffect(() => { 
    
    const gameUpdater = async () => {
      var action
      if (param['act'] == "unlock") {
        if (param['pid']) {
          action = "UNLOCK,PHOTO," + param['pid']
        } else if (param['cid']) {
          action = "UNLOCK,CACHE," + param['cid']
        }
      } else {
        console.log("trail load")
        action = "LOAD"
      }
      gameUpdate(action)
      
    }
    gameUpdater()
   
  }, []);

  //map stuff

  function isEmpty(obj) {

    if ((obj === "") || (obj == null)) {
      return true
   }
    return false
  }

  const [mapLoaded, setMapLoaded] = useState(false)
  const [map, setMap] = useState({})
  const [google, setGoogle] = useState()

  const decodePolyline = require('decode-google-map-polyline');
  var polyline1Path = 'ibnuBrmh|QK_CqE}Ab@aCe@I'
  var polyline2Path = 'chnuBrbh|Qx@iE_@I'
  var polyline3Path = 'cgnuBt{g|Qw@i@oBo@l@{Cg@K'
  var polyline4Path = 'elnuBrsg|QiLgCgBvD]I[FQPGPyH{AgDl@aBaN'
  var polyline5Path = 'wqouBveg|Qb@~CyIrA'
  var polyline6Path = 'm{ouBjmg|Qe@v@d@`D'


  const markerClick = (type, id) => {
    
    //setMarkerVisibility(markerVisibility + 1)

    if (type === "CACHE") {
      props.history.push({
        pathname: '/cache',
        search: '?id='+ param['id'] +'&pc=' + param['pc'] + '&cid=' + id
      })
    } else if (type === "PHOTO") {
      props.history.push({
        pathname: '/photo',
        search: '?id='+ param['id'] +'&pc=' + param['pc'] + '&pid=' + id
      })
    }
  }

  var lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4
  };


  const handleMapLoad = (map, google) => {
    setMap(map)
    setGoogle(google)


    var cacheMarker1 = new google.maps.Marker({
      map: map,
      position: {lat: cacheData[0]['lat'], lng: cacheData[0]['lng']},
      visible: true,
      icon: {url: cacheData[0]['mapPin'],
             scaledSize: new google.maps.Size(cacheData[0]['pinSize'],cacheData[0]['pinSize'])}
    });

    cacheMarker1.addListener('click', function() {
          markerClick("CACHE", 1)
        });

    if (isEmpty(state.game['1-found'])) {
      var infowindow = new google.maps.InfoWindow({
        content: "<div style='margin: 5px 20px 10px 0px; font-size: 1.4em; text-align: center;'>" + strings['marker-start'] + "</div>"
      });

      infowindow.open(map, cacheMarker1);
    } 

    addMapStuff();

  }

  //Loads map based on game state
  useEffect(() => { 
    addMapStuff();
   
  }, [google]);

  //Update map on based on game update

  const [modalTipMapShow, setModalTipMapShow] = useState(false)

  useEffect(() => { 
    if (google) {  
      addMapStuff();
    }

    if (state.game && isEmpty(state.game['1-found'])) {
      setModalTipMapShow(true)
    }
  
  }, [state.game]);

  const addMapStuff = () => {

    if (google) {

      if (!isEmpty(state.game['1-found']))
      {
        var polyline1 = new google.maps.Polyline({
            path: decodePolyline(polyline1Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });

        var instaMarker1 = new google.maps.Marker({
          map: map,
          position: {lat: photoData[0]['lat'], lng: photoData[0]['lng']},
          visible: true,
          icon: {url: photoData[1]['mapPin'],
                 scaledSize: new google.maps.Size(photoData[0]['pinSize'],photoData[0]['pinSize'])}
        });

        instaMarker1.addListener('click', function() {
              markerClick("PHOTO", 1)
            });
       

        polyline1.setMap(map)
      }

      if (!isEmpty(state.game['1p-unlock']))
      {
        map.panTo(new google.maps.LatLng(cacheData[1]['lat'], cacheData[1]['lng']));

        var polyline2 = new google.maps.Polyline({
            path: decodePolyline(polyline2Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });

        var cacheMarker2 = new google.maps.Marker({
          map: map,
          position: {lat: cacheData[1]['lat'], lng: cacheData[1]['lng']},
          visible: true,
          icon: {url: cacheData[1]['mapPin'],
                 scaledSize: new google.maps.Size(cacheData[1]['pinSize'],cacheData[1]['pinSize'])}
        });

        cacheMarker2.addListener('click', function() {
              markerClick("CACHE", 2)
            }); 
        

        polyline2.setMap(map)
      }

      if (!isEmpty(state.game['2-found']))
      {
        var polyline3 = new google.maps.Polyline({
            path: decodePolyline(polyline3Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });
        var instaMarker2 = new google.maps.Marker({
          map: map,
          position: {lat: photoData[1]['lat'], lng: photoData[1]['lng']},
          visible: true,
          icon: {url: photoData[1]['mapPin'],
                 scaledSize: new google.maps.Size(photoData[1]['pinSize'],photoData[1]['pinSize'])}
        });

        instaMarker2.addListener('click', function() {
              markerClick("PHOTO", 2)
            });

        polyline3.setMap(map)
      }

      if (!isEmpty(state.game['2p-unlock']))
      {
        map.panTo(new google.maps.LatLng(19.414391, -99.167798));

        var polyline4 = new google.maps.Polyline({
            path: decodePolyline(polyline4Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });

        var cacheMarker3 = new google.maps.Marker({
          map: map,
          position: {lat: cacheData[2]['lat'], lng: cacheData[2]['lng']},
          visible: true,
          icon: {url: cacheData[2]['mapPin'],
                 scaledSize: new google.maps.Size(cacheData[2]['pinSize'],cacheData[2]['pinSize'])}
        });

        cacheMarker3.addListener('click', function() {
              markerClick("CACHE", 3)
            });

        if (isEmpty(state.game['3-found'])) {
          var infowindow = new google.maps.InfoWindow({
            content: "<div style='margin: 5px 20px 10px 0px; font-size: 1.4em; text-align: center;'>" + strings['route-tip'] + "</div>"
          });

          infowindow.open(map, cacheMarker3);
        }
        
        polyline4.setMap(map)
      }

      if (!isEmpty(state.game['3-found']))
      {

        map.panTo(new google.maps.LatLng(19.417486, -99.167645));
        
        var polyline5 = new google.maps.Polyline({
            path: decodePolyline(polyline5Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });
        var instaMarker3 = new google.maps.Marker({
          map: map,
          position: {lat: photoData[2]['lat'], lng: photoData[2]['lng']},
          visible: true,
          icon: {url: photoData[2]['mapPin'],
                 scaledSize: new google.maps.Size(photoData[2]['pinSize'],photoData[2]['pinSize'])}
        });

        instaMarker3.addListener('click', function() {
              markerClick("PHOTO", 3)
            });

        polyline5.setMap(map)
      }

      if (!isEmpty(state.game['3p-unlock']))
      {
        var polyline6 = new google.maps.Polyline({
            path: decodePolyline(polyline6Path),
            strokeOpacity: 0,
            strokeColor: '#fb3e3e',
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px' }]
          });

         var cacheMarker4 = new google.maps.Marker({
          map: map,
          position: {lat: cacheData[3]['lat'], lng: cacheData[3]['lng']},
          visible: true,
          icon: {url: cacheData[3]['mapPin'],
                 scaledSize: new google.maps.Size(cacheData[3]['pinSize'],cacheData[3]['pinSize'])}
        });

        cacheMarker4.addListener('click', function() {
              markerClick("CACHE", 4)
            });

        var infowindow = new google.maps.InfoWindow({
          content: "<div style='margin: 5px 20px 10px 0px; font-size: 1.4em; text-align: center;'>" + strings['marker-end'] + "</div>"
        });

        infowindow.open(map, cacheMarker4);

        polyline6.setMap(map)
      }
    }
  }

  //Offline map setup
  const queryBase = '?id='+ param['id'] +'&pc=' + param['pc']
  const offlineMap = {name: "offline-map",
                     areas: [{shape: "rect", coords: cacheData[0].imageMap, href:"CACHE"  },
                             {shape: "rect", coords: photoData[0].imageMap, href:"PHOTO" },
                             {shape: "rect", coords: cacheData[1].imageMap, href:"CACHE" },
                             {shape: "rect", coords: photoData[1].imageMap, href:"PHOTO" },
                             {shape: "rect", coords: cacheData[2].imageMap, href:"CACHE" },
                             {shape: "rect", coords: photoData[2].imageMap, href:"PHOTO" },
                             {shape: "rect", coords: cacheData[3].imageMap, href:"CACHE" }]}
  
    
	return (
    <div className="full-vert">

    {navigator.onLine ?

          <Map
            id="myMap"
            options={{
              center: { lat: 19.412366, lng: -99.170601 },
              zoom: 16, 
              disableDefaultUI: true
            }}
            onMapLoad = {handleMapLoad}
          /> 
    : 

      <React.Fragment>

        <ModalMapTip
          show={modalTipMapShow}
          onHide={() => setModalTipMapShow(false)}
          closeModal={() => setModalTipMapShow(false)}
        />

        <ImageMapper src={"images/offline-map.jpg"} onClick={(area, index) => markerClick(area.href, Math.floor(index/2) + 1)} map={offlineMap} width={window.innerWidth} imgWidth={1216} />  
      
      </React.Fragment>
       
    }
        

         {/* SocialMedia Component*/}
         </div>
  );
}

//offline mode
export default Trail

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAdPJnLq3bHgGR7d59uCWwmDFdnvlxc4gM'
// })(Trail)
